import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Confetti from 'react-confetti';
import './App.css';

function App() {
  const [countdown, setCountdown] = useState(3); // Countdown starts at 10
  const [blurLevel, setBlurLevel] = useState(6); // Start with heavy blur
  const [showConfetti, setShowConfetti] = useState(false); // Control confetti

  useEffect(() => {
    // Countdown and blur effect
    const timer = setInterval(() => {
      if (countdown > 0) {
        setCountdown((prev) => prev - 1);
        setBlurLevel((prev) => Math.max(prev - 2, 0)); // Gradually reduce blur until it reaches 0
      } else {
        clearInterval(timer);
        setShowConfetti(true); // Trigger confetti when countdown is done
        setTimeout(() => setShowConfetti(false), 12000); // Confetti lasts for 12 seconds
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [countdown]);

  return (
    <div style={{ padding: 0 }}>
      <div className={`main-content`} style={{ filter: `blur(${blurLevel}px)` }}>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="*" element={<HomePage />} />
          </Routes>
        </Router>
      </div>

      {/* Countdown display */}
      {countdown > 0 && (
        <div className="countdown-overlay">
          {/* <h1>{countdown}</h1> */}
        </div>
      )}

      {/* Show confetti when countdown ends */}
      {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} />}
    </div>
  );
}

export default App;
