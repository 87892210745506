import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import '../css/memberList.css'
import paras from '../images/members/paras2.jpg';
import het from '../images/members/het.jpg';
import chintan from '../images/members/chintan.jpg';
import sani from '../images/members/sani.jpg';
import neel from '../images/members/neel.jpg';
import neet from '../images/members/neet.jpg';
import vaibhav from '../images/members/vaibhav.jpg';
import harshit from '../images/members/harshit.jpg';
import siddharth from '../images/members/siddharth.jpg';
import chirag from '../images/members/chirag.jpg';
import yash from '../images/members/yash.jpg';
import sahil from '../images/members/sahil.jpg';
import sharad from '../images/members/sharad.jpg';
import dutt from '../images/members/dutt.jpg';
import akshar from '../images/members/akshar.jpg';
import naitik from '../images/members/naitik.jpg';
import riddham from '../images/members/riddham.jpg';
import trushank from '../images/members/trushank.jpg';

const members = [
    {
        name: 'Akshar Patel',
        village: 'Ras',
        fact: 'Ubhor pela vicks no godi khavade pachi bolase!',
        imageUrl: akshar
      },
      {
        name: 'Siddharth Patel',
        village: 'Vadodara',
        fact: 'national IT seva lead chu',
        imageUrl: siddharth
      },
      {
        name: 'Dutt Patel',
        village: 'philadelphia',
        fact: 'hu WFG ne pan ramadu!',
        imageUrl: dutt
      },
  {
    name: 'Paras Patel',
    village: 'Sihol',
    fact: 'khabar nay',
    imageUrl: paras
  },
  {
    name: 'Het Surani',
    village: 'Surendranagar',
    fact: 'Hu to gapata maru khali - baida fati gya che',
    imageUrl: het
  },
  {
    name: 'Sani Patel',
    village: 'Sihol',
    fact: 'Thala for a reason',
    imageUrl: sani
  },
  {
    name: 'Neel Patel',
    village: 'Bechri',
    fact: 'mara pappa - bharat bechri',
    imageUrl: neel
  },
  {
    name: 'Neet Patel',
    village: 'Ras',
    fact: ' I\'m from dalllaass',
    imageUrl: neet
  },
  {
    name: 'Yash Karkar',
    village: 'Surat',
    fact: 'hu to cloud par chu',
    imageUrl: yash
  },
  {
    name: 'Chintan Patel',
    village: 'Bodal',
    fact: 'Ppl call me CP',
    imageUrl: chintan
  },
  {
    name: 'Vaibhav Patel',
    village: 'Borsad',
    fact: 'Mare Ottawa javu che',
    imageUrl: vaibhav
  },
  {
    name: 'Trushank Patel',
    village: 'Surendranagar',
    fact: 'mereko saptrang pasand hai!',
    imageUrl: trushank
  },
  {
    name: 'Naitik Parekh',
    village: 'Karamsad',
    fact: 'chalo puda banaie',
    imageUrl: naitik
  },
  {
    name: 'Riddham Mavani',
    village: 'Gadhda',
    fact: 'Hu shayona ma drive-through chalu karis',
    imageUrl: riddham
  },
  {
    name: 'Sahil Patel',
    village: 'Amdavad',
    fact: 'Hu pacho aais pachi kais',
    imageUrl: sahil
  },
  {
    name: 'Sharad Vaghasiya',
    village: 'Rohishala',
    fact: 'dimond dimond dimond',
    imageUrl: sharad
  },
  {
    name: 'Chirag Patel',
    village: 'Surendranagar',
    fact: 'Hu kathiyavad no warren buffett!',
    imageUrl: chirag
  },
  {
    name: 'Harshit Patel',
    village: 'Vadodara',
    fact: 'Hu to vsco vadi j lavis!!!',
    imageUrl: harshit
  }

  // Add more members as per your requirement
];

function MemberCard({ member }) {
  return (
  
    <Col xs={12} sm={6} md={4} className="mb-4"> {/* Use responsive column sizes */}
      <Card>
        <Row className="no-gutters">
          <Col xs={4} md={4} className="d-flex align-items-center justify-content-center">
            <Card.Img
              variant="top"
              src={member.imageUrl}
              alt={member.name}
              style={{ 
                width: '100%', 
                height: 'auto', 
                maxHeight: '200px', 
                objectFit: 'cover',
                padding: '8px'  
              }}
            />
          </Col>
          <Col xs={8} md={8}>
            <Card.Body>
              <Card.Title>{member.name}</Card.Title>
              <Card.Text>
                <strong>{member.village}</strong> <br />
                {member.fact}
              </Card.Text>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </Col>
    
  );
}

function MemberList() {
  const [searchParams, setSearchParams] = useState({
    parliament: 'Current Members',
    village: 'All',
    fact: 'All',
    gender: 'All',
    lastName: '',
  });

  const handleInputChange = (e) => {
    setSearchParams({
      ...searchParams,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Container fluid>
      
      <Row>
        {members.map((member, index) => (
          <MemberCard key={index} member={member} />
        ))}
      </Row>
    </Container>
  );
}

export default MemberList;
