import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import img1 from '../images/carousel/1.jpeg';
import img2 from '../images/carousel/2.jpeg';
import img3 from '../images/carousel/3.jpeg';
import img4 from '../images/carousel/4.jpeg';
import img5 from '../images/carousel/5.jpeg';
import '../css/homepage.css'; // Update CSS to include the animation styles
import MemberList from './MemberListComponent';
import Footer from './footer';
import Header from './Header';
import backgroundImg from '../images/background.jpg'; // Add your background image

function HomePageComponent() {
    const images = [img1, img2, img3, img4, img5];
    const [showContent, setShowContent] = useState(false); // Control visibility of main content

    useEffect(() => {
        // Delay before showing the main content
        const timer = setTimeout(() => {
            setShowContent(true);
        }, 5000); // Delay of 5 seconds

        return () => clearTimeout(timer);
    }, []);

    return (
        <Container fluid className="my-container">
            {!showContent ? (
                // Character-by-character animated text with background image
                <div
                    className="welcome-screen"
                    style={{
                        backgroundImage: `url(${backgroundImg})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        color: '#fff'
                    }}
                >
                  <h1 className="animated-text">
    {'YODHHA YUVANO'.split('').map((char, index) => (
        <span
            key={index}
            className="char"
            style={{
                animationDelay: `${index * 0.2}s`,
                display: char === ' ' ? 'inline-block' : 'inline', // Handle spaces
                width: char === ' ' ? '10px' : 'auto' // Adjust width for space
            }}
        >
            {char === ' ' ? '\u00A0' : char} {/* Render non-breaking space for proper spacing */}
        </span>
    ))}
</h1>

                </div>
            ) : (
                // The main content appears after the delay
                <>
                    <Header />
                    {/* Centering the Carousel */}
                    <Row className="justify-content-center">
                        <Col xs={12} md={10} lg={8} className="text-center">
                            <Carousel fade>
                                {images.map((src, index) => (
                                    <Carousel.Item key={index}>
                                        <img className="d-block w-100" src={src} alt={`Slide ${index + 1}`} />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </Col>
                    </Row>

                    <br />
                    <MemberList />
                    <Footer />
                </>
            )}
        </Container>
    );
}

export default HomePageComponent;
