import HomePageComponent from '../components/HomePageComponent';

function HomePage() {
    return (
        <HomePageComponent />
        
      
    );
  }
  
  export default HomePage;