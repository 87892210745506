import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import '../css/footer.css'; // Optional: Import CSS for additional styling

const Footer = () => {
  return (
    <footer className="bg-dark text-light py-4">
      <Container>
        <Row>
          <Col md={4} className="mb-3">
            <h5>About Us</h5>
            <p>
              We are a group of dedicated individuals committed to serving our community and providing valuable information to our members.
            </p>
          </Col>
          <Col md={4} className="mb-3">
            <h5>Quick Links</h5>
            <Nav className="flex-column">
              <Nav.Link href="#home" className="text-light">Home</Nav.Link>
              <Nav.Link href="#about" className="text-light">About</Nav.Link>
              <Nav.Link href="#services" className="text-light">Services</Nav.Link>
              <Nav.Link href="#contact" className="text-light">Contact</Nav.Link>
            </Nav>
          </Col>
          <Col md={4} className="mb-3">
            <h5>Contact Us</h5>
            <p>Email: yodhhas@yodhhayuvano.ca</p>
            <p>Phone: (437) 937-8080</p>
            <p>Address: Brampton/ Barrie/ Orillia/ Saskatoon</p>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-3">
            <small>&copy; {new Date().getFullYear()} Yodhha Yuvano Inc. All Rights Reserved.</small>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
