import React from 'react';
import '../css/header.css'; // Create a CSS file for styles

function Header() {
    return (
        <header className="header">
            <h1 className="animated-title">YODHHA YUVANO</h1>
        </header>
    );
}

export default Header;
